import { useState } from "react"
import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node"
import { useLoaderData, Link } from "@remix-run/react"
import moment from "moment-timezone"
import { ReachRow } from "../components/ReachRow"
import { TopBanner } from "../components/TopBanner"
import type {
  RYMDay,
  RYMInfo,
  Campaign,
  CampaignDateRange,
} from "../utils/helpers"
import {
  getCoverImage,
  getDescriptor,
  slugify,
  dateLabel,
  getLiveDateRange,
  getAdImage,
} from "../utils/helpers"
import { Offer } from "../components/Offer"
import { ShareModal } from "../components/ShareModal"
import { EpisodePlayer } from "../components/EpisodePlayer"
import { metadataMapper } from "~/utils/metaMapper"

export const handle = {
  bodyId: "broadcast",
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const title = data?.day?.episode?.title
    ? "Renewing Your Mind | " +
      data.day.episode?.title +
      " | " +
      dateLabel(data.day.date, false)
    : "Renewing Your Mind with R.C. Sproul"

  const description =
    data?.day?.episode?.description ||
    "Your daily destination for trustworthy Christian teaching and in-depth Bible study."
  const image = "https://renewingyourmind.org/images/social.jpg"

  const metadata = metadataMapper({
    title,
    description,
    image,
    openGraphUrl: data?.permalink,
  })
  return metadata
}

const getBroadcast = async (stamp: string) => {
  const LIGHOST = process.env.LIGHOST
  const LIGAPITOKEN = process.env.LIGAPITOKEN
  const url = `${LIGHOST}/api/v1/rym/${stamp}/`

  return fetch(url, {
    method: "GET",
    mode: "no-cors",
    headers: {
      Authorization: `Bearer ${LIGAPITOKEN}`,
    },
  })
}

const getBroadcasts = async () => {
  const LIGHOST = process.env.LIGHOST
  const LIGAPITOKEN = process.env.LIGAPITOKEN
  const url = `${LIGHOST}/api/v1/rym/?limit=121`

  return fetch(url, {
    method: "GET",
    mode: "no-cors",
    headers: {
      Authorization: `Bearer ${LIGAPITOKEN}`,
    },
  })
}

const getCampaigns = async () => {
  const LIGHOST = process.env.LIGHOST
  const LIGAPITOKEN = process.env.LIGAPITOKEN
  const url = `${LIGHOST}/api/v1/campaigns/?brand=Renewing+Your+Mind`

  return fetch(url, {
    method: "GET",
    mode: "no-cors",
    headers: {
      Authorization: `Bearer ${LIGAPITOKEN}`,
    },
  })
}

function getCampaign(campaigns: Array<Campaign> | null) {
  // might need a conditional to check if there's a campaigns array
  // before running the below code
  let dateRange: CampaignDateRange | undefined
  const campaign = campaigns?.find((camp: Campaign) => {
    dateRange = getLiveDateRange(camp.dates)

    return Boolean(dateRange)
  })

  return { campaign, dateRange }
}

export async function loader({ params, request }: LoaderFunctionArgs) {
  // Ligonier uses the EST timezone
  const todayStamp = moment().tz("America/New_York").format("YYYY/MM/DD")

  let stamp = todayStamp

  // The year, month, and day params are present when the loader is called by the
  // $year.$month.$day.($slug) route, which imports and re-exports this loader.
  if (params?.year) {
    stamp = `${params?.year || ""}/${params?.month || ""}/${params?.day || ""}`
    const today = moment().format("MM/DD/YYYY")
    const previewAttempt = request.url.includes("?preview=true")
    const isAfterToday = moment(stamp).isAfter(today)

    if (
      !moment(stamp, "YYYY/MM/DD").isValid() ||
      (isAfterToday && !previewAttempt)
    ) {
      throw new Response("Not Found", {
        status: 404,
      })
    }
  }

  const broadcastPromise = getBroadcast(stamp)
  const broadcastsPromise = getBroadcasts()
  const campaignsPromise = getCampaigns()
  const responses = await Promise.all([
    broadcastPromise,
    broadcastsPromise,
    campaignsPromise,
  ])

  if (!responses[0].ok) {
    throw new Response(responses[0].statusText, {
      status: responses[0].status,
    })
  }

  // TODO: add caching of these values
  const day = (await responses[0].json()) as RYMDay
  const broadcasts = responses[1].ok
    ? ((await responses[1].json()) as Array<RYMInfo>)
    : null
  const campaigns = responses[2].ok
    ? ((await responses[2].json()) as Array<Campaign>)
    : null

  const { campaign, dateRange: campaignDateRange } = getCampaign(campaigns)

  const broadcastDate = moment(day.date, "YYYY/MM/DD")
  const prevDate = broadcastDate.clone().subtract(1, "days")
  const nextDate = broadcastDate.clone().add(1, "days")
  const prevStamp = prevDate.format("YYYY/MM/DD")
  const nextStamp = nextDate.format("YYYY/MM/DD")
  const prevBroadcast = broadcasts?.find((item) => item.date == prevStamp)
  const nextBroadcast = broadcasts?.find((item) => item.date == nextStamp)
  const prevSlug = slugify(prevBroadcast?.title || "")
  const nextSlug = slugify(nextBroadcast?.title || "")
  const prevUrl = prevBroadcast && `/${prevStamp}${prevSlug && "/" + prevSlug}`
  const nextUrl = nextBroadcast && `/${nextStamp}${nextSlug && "/" + nextSlug}`
  const isToday = day.date == todayStamp

  const slug = slugify(day.episode.title || "")

  return {
    title: day.episode?.title || "",
    descriptor: getDescriptor(day.episode),
    description: day.episode?.description || "",
    coverImage: getCoverImage(day),
    permalink:
      "https://renewingyourmind.org/" + stamp + (slug ? `/${slug}` : ""),
    prevDayUrl: prevUrl,
    nextDayUrl: isToday ? "" : nextUrl,
    dateLabel: dateLabel(day.date, isToday),
    mp3: day.episode.mp3 || "",
    isToday,
    campaign,
    campaignDateRange,
    GIFTLIGHOST: process.env.GIFTLIGHOST || "https://gift.renewingyourmind.org",
    serverNow: Date.now(),
    day,
  }
}

export default function Index() {
  const data = useLoaderData<typeof loader>()
  const { campaign, campaignDateRange, GIFTLIGHOST } = data
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  return (
    <main role="main">
      <div id="wrapper">
        <div id="top-banner-holder" className="toggle-content">
          <div className="container">
            <TopBanner />
          </div>
        </div>
        <div id="main-screen">
          <section id="rym" data-testid="banner-section">
            <article id="episode" className="container">
              <h2 className="label">{data.dateLabel}</h2>
              <EpisodePlayer
                title={data.title}
                mp3={data.mp3}
                prevDayUrl={data.prevDayUrl}
                nextDayUrl={data.nextDayUrl}
              />
              <div className="meta">
                {data.prevDayUrl ? (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <Link
                    to={data.prevDayUrl}
                    className="left-btn"
                    aria-label="Go to previous day"
                  />
                ) : (
                  ""
                )}
                {data.nextDayUrl ? (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <Link
                    to={data.nextDayUrl}
                    className="right-btn"
                    aria-label="Go to next day"
                  />
                ) : (
                  ""
                )}
                <h2>{data.descriptor}</h2>
                <button
                  id="share-btn"
                  className="share"
                  onClick={() => {
                    setModalVisible(!modalVisible)
                  }}
                  data-testid="share-button"
                >
                  Share
                </button>
                {modalVisible && (
                  <ShareModal
                    message={data.title}
                    link={data.permalink}
                    shareType="broadcast"
                    close={() => setModalVisible(false)}
                  />
                )}
                <div
                  className="meta-details"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></div>
              </div>
            </article>
            <div
              id="cover"
              style={{
                backgroundImage: `url(${data.coverImage})`,
              }}
            ></div>{" "}
          </section>
          {/*<script>
                Variables for Tracking Purposes
                var episodeTracking = [];
                {if day.date} episodeTracking.date = "{day.date}"; {else} episodeTracking.date = "N/A"; {end}
                {if day.episode.title} episodeTracking.title = "{day.episode.title}"; {else} episodeTracking.title = "N/A"; {end}
                {if teacher} episodeTracking.teacher = "{teacher}"; {else} episodeTracking.teacher = "N/A"; {end}
                {if seriesTitle} episodeTracking.seriesTitle = "{seriesTitle}"; {else} episodeTracking.seriesTitle = "N/A"; {end}
            {/*</script>*/}
          <section id="offer-holder" data-testid="offer-section">
            <div className="container">
              {campaign?.offer && (
                <div
                  id="offer"
                  className={`col-100 ${
                    campaign.offer?.products?.length > 1 ? "bundle" : ""
                  }`}
                >
                  <Link
                    id="offer-copy"
                    className="col-45 copy"
                    to={`${GIFTLIGHOST}/${campaign.id}/${campaign.slug}?utm_source=renewing+your+mind&utm_medium=headline&utm_campaign=offer`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h2 className="label ignore-override">
                      <em className="ignore-override">Today&rsquo;s Offer</em>
                    </h2>
                    {campaign.offer.ad && (
                      <h1
                        className="ignore-override"
                        dangerouslySetInnerHTML={{ __html: campaign.offer.ad }}
                      />
                    )}
                  </Link>
                  <Link
                    id="offer-image"
                    className="col-25 image"
                    to={`${GIFTLIGHOST}/${campaign.id}/${campaign.slug}?utm_source=renewing+your+mind&utm_medium=resource&utm_campaign=offer`}
                    target="_blank"
                    style={{ backgroundImage: `url(${getAdImage(campaign)})` }}
                    rel="noreferrer"
                  >
                    <span className="visually-hidden">link to campaign</span>
                  </Link>
                  <Offer
                    campaign={campaign}
                    host={GIFTLIGHOST}
                    endTime={campaignDateRange?.ends}
                    serverNow={data.serverNow}
                  />
                </div>
              )}
              {campaign && !campaign?.offer && (
                <div id="offer" className="col-100">
                  <Link
                    id="offer-copy"
                    className="col-45 copy"
                    to={`${GIFTLIGHOST}/${campaign.id}/${campaign.slug}?utm_source=renewing+your+mind&utm_medium=headline&utm_campaign=offer`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h2 className="label ignore-override">
                      <em className="ignore-override">
                        Thank you for your support
                      </em>
                    </h2>
                    {campaign.ad && (
                      <h1
                        className="ignore-override"
                        dangerouslySetInnerHTML={{ __html: campaign.ad }}
                      />
                    )}
                  </Link>
                  <Offer
                    campaign={campaign}
                    host={GIFTLIGHOST}
                    endTime={campaignDateRange?.ends}
                    serverNow={data.serverNow}
                  />
                </div>
              )}
            </div>
          </section>
        </div>
        <section className="row broadcast-bottom">
          <div className="container">
            <div className="col-45">
              <img
                src="/images/RCSproul.jpg"
                loading="lazy"
                alt="R.C. Sproul"
                style={{ paddingTop: 0, paddingBottom: 0 }}
              />
            </div>
            <div className="col-55" style={{ paddingBottom: 0 }}>
              <h3 className="title">Broadcasting Truth for 30 Years</h3>
              <p>
                <em>Renewing Your Mind</em> delivers daily Bible teaching to
                listeners worldwide. R.C. Sproul launched this outreach in 1994
                to help people gain a deeper understanding of who God is as
                their minds are renewed according to His Word.
              </p>
              <h3 className="subtitle">Featured Teacher</h3>
              <p>
                R.C. Sproul (1939–2017) was known for his ability to winsomely
                and clearly communicate deep, practical truths from God’s Word.
                He was founder of{" "}
                <Link
                  to="https://www.ligonier.org"
                  target="_blank"
                  data-testid="featured-teacher-ligonier"
                >
                  Ligonier Ministries
                </Link>
                , first minister of preaching and teaching at{" "}
                <Link
                  to="https://sachapel.com"
                  target="_blank"
                  data-testid="featured-teacher-sachapel"
                >
                  Saint Andrew’s Chapel
                </Link>
                , first president of{" "}
                <Link
                  to="https://www.reformationbiblecollege.org"
                  target="_blank"
                  data-testid="featured-teacher-rbc"
                >
                  Reformation Bible College
                </Link>
                , and executive editor of{" "}
                <em>
                  <Link
                    to="https://tabletalkmagazine.com"
                    target="_blank"
                    data-testid="featured-teacher-tabletalk"
                  >
                    Tabletalk
                  </Link>
                </em>{" "}
                magazine.
              </p>
            </div>
            <hr />
            <div className="col-25">
              <h2 className="label">
                <em>Ways to Listen</em>
              </h2>
            </div>
            <div className="col-75">
              <div className="ways-to-listen">
                <Link
                  className="daily-email"
                  to="https://updates.ligonier.org/rym.html"
                  target="_blank"
                  rel="noreferrer"
                  data-testid="listen-email"
                >
                  Email
                </Link>
                <Link
                  className="podcast"
                  to="https://itunes.apple.com/us/podcast/renewing-your-mind-r.c.-sproul/id110916650?mt=2"
                  target="_blank"
                  rel="noreferrer"
                  data-testid="listen-podcast"
                >
                  Podcast
                </Link>
                <Link
                  className="radio"
                  to="/stations"
                  data-testid="listen-radio"
                >
                  Radio
                </Link>
                <Link
                  className="ligonier-app"
                  to="http://onelink.to/ligonier"
                  target="_blank"
                  rel="noreferrer"
                  data-testid="listen-ligonier"
                >
                  App
                </Link>
                <Link
                  className="refnet"
                  to="http://onelink.to/refnet"
                  target="_blank"
                  rel="noreferrer"
                  data-testid="listen-refnet"
                >
                  RefNet
                </Link>
              </div>
            </div>
            <hr />
            <div className="col-55" style={{ paddingBottom: 0 }}>
              <h3 className="title">A Daily Podcast for Growing Christians</h3>
              <p>
                Join us on this daily journey toward spiritual growth by God’s
                grace as His Word is faithfully explained, clearly understood,
                and actively applied. Each episode contains biblical teaching
                and a resource offer to help you further your study.
              </p>
              <h2 className="subtitle">Meet the Host</h2>
              <p>
                Nathan W. Bingham is vice president of ministry engagement for{" "}
                <Link
                  to="https://www.ligonier.org/"
                  target="_blank"
                  data-testid="host-ligonier"
                >
                  Ligonier Ministries
                </Link>
                , executive producer and host of <em>Renewing Your Mind</em>,
                host of the{" "}
                <Link
                  to="https://www.ligonier.org/podcasts/ask-ligonier"
                  target="_blank"
                  data-testid="host-ask"
                >
                  <em>Ask Ligonier</em>
                </Link>{" "}
                podcast, and a graduate of Presbyterian Theological College in
                Melbourne, Australia. Nathan joined Ligonier in 2012 and lives
                in Central Florida with his wife and four children.
              </p>
            </div>
            <div className="col-45">
              <img
                src="/images/NWB2.jpg"
                loading="lazy"
                alt="Nathan W. Bingham"
                style={{ paddingTop: 0, paddingBottom: 0 }}
              />
            </div>
            <hr />
            <div className="col-25">
              <h2 className="label">
                <em>Our Aim</em>
              </h2>
            </div>
            <div className="col-75">
              <p className="leadin">
                God calls His people to be transformed by the renewing of their
                minds. This transformation of thinking God’s thoughts after Him
                is a daily pursuit. To assist you in this,{" "}
                <em>Renewing Your Mind</em> features messages from gifted
                Christian teachers on theology, worldview and culture, biblical
                studies, Christian living, and church history. Think of the
                podcast as a daily resource to help you dig deeper into
                Scripture and apply its timeless truth to everyday life.
              </p>
              <p className="right our-aim-link">
                <Link to="/about" data-testid="our-aim-link">
                  More About this Outreach ›
                </Link>
              </p>
            </div>
          </div>
        </section>
        <ReachRow />
      </div>
    </main>
  )
}
